import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
// export default function Pricing() {

export default function Erp_Solution() {
  const particle = React.useRef(null);

  const [yearly, setYearly] = useState(false);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 data-aos="fade-up">ERP Software</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  Transform Your Business with Our Comprehensive ERP Solution.
                </p>
                <div className="contact-cta ">
                <a href="/book-demo" className="btn btn-primary">Book a Demo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="intro section">
        <div className="container-fluid">
          <div className="container position-relative">
            <div className="row d-flex">
              <div className="col-lg-12">
                <p className="mb-2 text-uppercase">Introduction to the ERP</p>
                <h2 data-aos="fade-up" style={{ color: "#f2c76e" }}>
                  Revolutionize the Way You Manage Your Business
                </h2>
                <p
                  className="homeBannerCnt_description"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  Our Enterprise Resource Planning (ERP) software is designed to
                  centralize and streamline various business functions,
                  enhancing overall operational efficiency. <br></br>
                  It simplifies day-to-day tasks such as attendance tracking,
                  payroll management, project oversight, and communication,
                  allowing teams to focus on strategic initiatives.
                </p>
                <br></br>
                <br></br>
              </div>
              <div className="col-lg-7">
                <div className="card-section">
                  <div className="card-icon">
                    <img
                      src="/assets/images/icons/complete-projects.png"
                      alt="ERP Icon"
                    />
                  </div>
                  <div className="card-content">
                    <h3>Improved Productivity</h3>
                    <p>
                      Automate repetitive tasks, reducing time spent on manual
                      processes.
                    </p>
                  </div>
                </div>
                <div className="card-section">
                  <div className="card-icon">
                    <img
                      src="/assets/images/icons/enhanced-collaboration.png"
                      alt="ERP Icon"
                    />
                  </div>
                  <div className="card-content">
                    <h3>Enhanced Collaboration</h3>
                    <p>
                      Foster better communication among teams with integrated
                      tools that facilitate information sharing and project
                      management.
                    </p>
                  </div>
                </div>
                <div className="card-section">
                  <div className="card-icon">
                    <img
                      src="/assets/images/icons/data-driven-decisions.png"
                      alt="ERP Icon"
                    />
                  </div>
                  <div className="card-content">
                    <h3> Data-Driven Decisions</h3>
                    <p>
                      Access real-time insights and analytics to make informed
                      decisions that drive growth and performance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <img
                  className="card-image"
                  src="/assets/images/erp-mockup.png"
                  alt="ERP Software"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="container my-5" id="featured">
          <div
            className="section-header"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h2>Mobile Application</h2>
            <p>
              Our easy-to-use mobile app allows your team to manage essential
              tasks on the go, from attendance tracking and project updates to
              submitting applications. Available for both iOS and Android, it
              ensures smooth, real-time access to your ERP features, even when
              away from the office.
            </p>
          </div>
          <div className="row">
            {/* <!-- start  left --> */}
            <div className="col-md-5 left">
              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="description">
                  <h4>
                    User
                    <span className="ltpColor"> Registration</span>
                  </h4>
                  <p>
                    Simplify onboarding with a straight forward registration
                    process for new employees.
                  </p>
                </div>
                <div className="icon">
                  <img src="/assets/images/icons/icon-1.png" alt="icon" />
                  <small className="text-uppercase ltpColor">1</small>
                </div>
              </div>

              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="description">
                  <h4>
                    Attendance
                    <span className="ltpColor"> Tracking</span>
                  </h4>
                  <p>
                    Streamline attendance tracking with real-time updates and
                    automated reports.
                  </p>
                </div>
                <div className="icon">
                  <img src="/assets/images/icons/icon-2.png" alt="icon" />
                  <small className="text-uppercase ltpColor">2</small>
                </div>
              </div>
              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="description">
                  <h4>
                    Project
                    <span className="ltpColor"> Updates</span>
                  </h4>
                  <p>
                    Stay informed with real-time project updates and
                    notifications.
                  </p>
                </div>
                <div className="icon">
                  <img src="/assets/images/icons/icon-3.png" alt="icon" />
                  <small className="text-uppercase ltpColor">3</small>
                </div>
              </div>
            </div>
            {/* <!-- end  left --> */}

            {/* <!-- start  center --> */}
            <div className="col-md-2 p-4 p-sm-5 center">
              <div
                className="list-center-wrap"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="center-icon">
                  <img src="/assets/images/icons/icon-center.png" alt="icon" />
                </div>
              </div>
            </div>
            {/* <!-- end  center --> */}
            {/* <!-- start  right --> */}
            <div className="col-md-5 right">
              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="icon">
                  <img src="/assets/images/icons/icon-4.png" alt="icon" />
                  <small className="text-uppercase ltpColor">4</small>
                </div>
                <div className="description">
                  <h4>
                    Application
                    <span className="ltpColor"> Submission</span>
                  </h4>
                  <p>
                    Submit applications with ease, ensuring timely processing
                    and feedback.
                  </p>
                </div>
              </div>

              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="icon">
                  <img src="/assets/images/icons/icon-5.png" alt="icon" />
                  <small className="text-uppercase ltpColor">5</small>
                </div>
                <div className="description">
                  <h4>
                    Resume
                    <span className="ltpColor"> Management</span>
                  </h4>
                  <p>
                    Employees can easily update their resumes, making it
                    effortless to apply for new roles.
                  </p>
                </div>
              </div>

              <div
                className="list-wrap"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="icon">
                  <img src="/assets/images/icons/icon-1.png" alt="icon" />
                  <small className="text-uppercase ltpColor">6</small>
                </div>
                <div className="description">
                  <h4>
                    Contract
                    <span className="ltpColor"> Management</span>
                  </h4>
                  <p>
                    Effortlessly manage signing and tracking of contracts and
                    joining letters.
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- end  right --> */}
          </div>
        </div>
      </section>
      <div className="section web-application">
        <div className="top-icon-box position-relative">
          <div className="container position-relative">
            <div className="section-header">
              <h2>Web Application</h2>
              <p>
                Our web-based platform provides a comprehensive dashboard for
                managing all business opera tions, from recruitment and project
                management to employee records and compliance.
              </p>
            </div>
            <div className="row gy-4">
              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Link to="/services/app-development">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/mobile-app-development.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Company Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Document storage and management.</li>
                        <li>Organizational policy integration.</li>
                        <li>Regulatory compliance adherence.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link to="/services/web-development">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/web-development.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Recruitment Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Job request submission through the app.</li>
                        <li>Online interview scheduling.</li>
                        <li>
                          Contract generation with QIWA Records and Social
                          Insurance data.
                        </li>
                        <li>
                          Digital contract signing and joining letter issuance.
                        </li>
                        <li>
                          Comprehensive approval flow (General Manager, HR,
                          Project Manager, Shift Supervisor).
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/software-development">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/software-development.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Attendance Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          Employees must be at the workplace to log attendance.
                        </li>
                        <li>
                          Location-based attendance tracking with Google Maps.
                        </li>
                        <li>Automatic daily attendance using QR codes.</li>
                        <li>Manual daily attendance sheet entry.</li>
                        <li>Notifications for absences or late logins.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Link to="/services/it-consulting">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/it-consulting.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Employee Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          Complete employee history from joining to the present.
                        </li>
                        <li>
                          Automatic employee termination for policy violations
                          with notifications.
                        </li>
                        <li>
                          Application submissions: promotion, leave, transfer,
                          and more.
                        </li>
                        <li>
                          Promotion eligibility tracking (minimum 6 months).
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link to="/services/digital-marketing">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/digital-marketing.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Timesheet & Shift Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Automated timesheet generation.</li>
                        <li>
                          Project-based manpower allocation for each shift.
                        </li>
                        <li>
                          Restriction on over-assigning employees to shifts.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/leave-management.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Leave Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Easy leave application submissions.</li>
                        <li>Approval workflows and notifications.</li>
                        <li>Leave balance tracking.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/payroll-salary-management.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Payroll & Salary Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Salary sheet generation.</li>
                        <li>Overtime tracking and calculation.</li>
                        <li>
                          Penalty deductions for absences and late arrivals.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/project-management.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Project Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          Activity tracking for projects, employees, and work
                        </li>
                        <li>
                          Client access to project status and employee
                          contributions.
                        </li>
                        <li>Real-time updates on project progress.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/compliance-and-notifications.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Compliance and Notifications
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          Automatic compliance checks aligned with Saudi Arabia
                        </li>
                        <li>
                          Real-time notifications for critical updates or policy
                          changes.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/live-location-tracking.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Live Location Tracking
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Real-time employee location monitoring.</li>
                        <li>Visualized through an integrated map interface.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/asset-and-resource-management.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Asset and Resource Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Inventory tracking and asset allocation.</li>
                        <li>Distribute assets to employees.</li>
                        <li>Distribute assets to projects.</li>
                        <li>Manage asset returns and repairs.</li>
                        <li>Maintenance schedules and alerts.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/messaging-system.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Messaging System
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          In-app communication between employees and management.
                        </li>
                        <li>SMS notifications sent via company/brand name.</li>
                        <li>
                          Email notifications to all employees, branches, or
                          projects.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/job-portal.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Job Portal
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Internal and external job postings.</li>
                        <li>
                          Candidate application tracking and interview
                          scheduling.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/online-exam-system.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Online Exam System
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Exam scheduling for employees.</li>
                        <li>Real-time monitoring with photo capture.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/investor-management.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Investor Management
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          Tools for managing and communicating with investors.
                        </li>
                        <li>Financial summaries and project updates.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/performance-analytics.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Performance Analytics
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>
                          KPIs for employee performance and project outcomes.
                        </li>
                        <li>
                          Visual dashboards with graphs, charts, and trends.
                        </li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}

              <div
                className="col-xl-4 col-md-4"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <Link to="/services/cloud-services">
                  <div className="icon-box text-left">
                    <div className="icon">
                      <img
                        src="/assets/images/icons/security-and-backup.png"
                        alt="icon"
                      />
                    </div>
                    <h4 className="title">
                      <a href="" className="stretched-link">
                        Security and Backup
                      </a>
                    </h4>
                    <p className="text-white">
                      <ul>
                        <li>Enterprise-grade data security.</li>
                        <li>Regular data backups and recovery options.</li>
                      </ul>
                    </p>
                  </div>
                </Link>
              </div>
              {/* <!--End Icon Box --> */}
            </div>
          </div>
        </div>
      </div>
      <div className="pricing section">
        <div className="container-fluid">
          <div className="container">
            <div
              className="section-header"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h2>Pricing</h2>
              <p>
                Our pricing plans are designed to meet your business needs,
                ensuring you get the most out of our comprehensive HR and
                management solutions.
              </p>
            </div>
            <div
              className="pricing-intro"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <ul className="pricing-highlights">
                <li>
                  <span className="highlight-label">One-Time Setup Cost: </span>
                  <span className="fw-bold highlight-value">$10,000</span>
                  <span className="highlight-details">
                    (includes installation, initial customization, and
                    onboarding).
                  </span>
                </li>
                <li>
                  <span className="highlight-label">
                    Monthly Subscription:{" "}
                  </span>
                  <span className="highlight-details">
                    Covers all software features, maintenance, updates, and
                    support.
                  </span>
                </li>
                <li>
                  <span className="highlight-label">Yearly Plans: </span>
                  <span className="highlight-details">
                    Get a <span className="highlight-value">10% discount</span>{" "}
                    by opting for a yearly subscription.
                  </span>
                </li>
              </ul>
            </div>
            <div className="switch-section mt-5">
              <div className="switch-sec">
                <button onClick={() => setYearly(false)}>Monthly</button>
                <button
                  className={`${yearly ? "is-active" : ""} switch-btn`}
                  onClick={() => setYearly(!yearly)}
                >
                  <div className="handle"></div>
                </button>
                <button onClick={() => setYearly(true)}>
                  Yearly <span style={{ color: "#f2c76e" }}>(Save 10%)</span>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-basic.png"
                      alt="icon"
                    />
                    <h2>Basic</h2>
                    <p>( 0 - 100 Employees )</p>
                  </div>
                  <div className="price">
                    {yearly && (
                      <span className="price-save">${Number(350 * 12)}</span>
                    )}
                    <h4>
                      <sup>$</sup>
                      {yearly ? "3,780" : "350"}
                      <sub style={{ fontSize: 14 + "px" }}>
                        /{yearly ? "year" : "month"}
                      </sub>
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        20GB Storage
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Full access to all modules
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        24/7 support, regular updates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Including Android and IOS app.
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-standard.png"
                      alt="icon"
                    />
                    <h2>Standard</h2>
                    <p>( 101 - 500 Employees )</p>
                  </div>
                  <div className="price">
                    {yearly && (
                      <span className="price-save">${Number(500 * 12)}</span>
                    )}
                    <h4>
                      <sup>$</sup>
                      {yearly ? "5,400" : "500"}
                      <sub style={{ fontSize: 14 + "px" }}>
                        /{yearly ? "year" : "month"}
                      </sub>
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        100GB Storage
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Full access to all modules
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        24/7 support, regular updates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Including Android and IOS app.
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-premium.png"
                      alt="icon"
                    />
                    <h2>Premium</h2>
                    <p>( 501 - 1,000 Employees )</p>
                  </div>
                  <div className="price">
                    {yearly && (
                      <span className="price-save">${Number(1000 * 12)}</span>
                    )}
                    <h4>
                      <sup>$</sup>
                      {yearly ? "10,800" : "1,000"}
                      <sub style={{ fontSize: 14 + "px" }}>
                        /{yearly ? "year" : "month"}
                      </sub>
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        1TB Storage
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Full access to all modules
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        24/7 support, regular updates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Including Android and IOS app.
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-custom.png"
                      alt="icon"
                    />
                    <h2>Custom</h2>
                    <p>( 1,000+ Employees )</p>
                  </div>
                  <div className="price">
                    {yearly && (
                      <span className="price-save-text">Save 10%</span>
                    )}
                    <h4 className="custom-h4">Call for Pricing</h4>
                  </div>
                  <div className="option">
                    <ul style={{ marginTop: 20 + "px" }}>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Custom Storage
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Full access to all modules
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        24/7 support, regular updates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Including Android and IOS app.
                      </li>
                    </ul>
                  </div>
                  <a href="#">Contact Us</a>
                </div>
              </div>
            </div>
            {/* Add after the pricing cards row */}
            <div className="pricing-details mt-5">
              <div className="row">
                <div
                  className="col-lg-6"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="customization-box">
                    <h3 className="mb-4">Additional Customization Details</h3>
                    <div className="mb-4">
                      <p className="fw-bold">Customization Cost:</p>
                      <p>
                        Pricing for maximum or full system customization will
                        vary based on the complexity and requirements provided
                        by the client.
                      </p>
                    </div>
                    <div className="mb-4">
                      <p className="fw-bold">Examples of Customization:</p>
                      <ul className="custom-list">
                        <li>
                          Additional modules specific to your business needs.
                        </li>
                        <li>Workflow changes and unique automation.</li>
                        <li>Third-party system integrations.</li>
                      </ul>
                    </div>
                    <p className="fst-italic">
                      Customization cost estimates will be provided after a
                      detailed consultation.
                    </p>
                  </div>
                </div>

                <div
                  className="col-lg-6 custom-top"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="notes-box">
                    <h3 className="mb-4">Additional Notes</h3>
                    <ul className="custom-list">
                      <li>
                        Prices are subject to change based on customizations and
                        add-on requirements.
                      </li>
                      <li>
                        Discounts available for multi-year subscriptions or bulk
                        purchases.
                      </li>
                      <li>
                        Flexible payment plans available to suit your business
                        needs.
                      </li>
                    </ul>

                    <div className="contact-cta text-center mt-4">
                      <p className="mb-4">
                        Contact Us for a personalized quote or demo tailored to
                        your company's specific requirements.
                      </p>
                      <a href="/contact" className="btn btn-primary">
                        Get in Touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

