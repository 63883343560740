import emailjs from "emailjs-com";
import React from "react";
import { Link } from "react-router-dom";

export default function Book_Demo() {
    const particle = React.useRef(null);
    React.useEffect(() => {
        window.particlesJS("particles-js", {
            particles: {
                number: {
                    value: 150,
                    density: {
                        enable: true,
                        value_area: 4000,
                    },
                },
                color: {
                    value: "#ffffff",
                },
                shape: {
                    type: "circle",
                    stroke: {
                        width: 0,
                        color: "#000000",
                    },
                    polygon: {
                        nb_sides: 5,
                    },
                    image: {
                        src: "img/github.svg",
                        width: 100,
                        height: 100,
                    },
                },
                opacity: {
                    value: 0.5,
                    random: false,
                    anim: {
                        enable: false,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false,
                    },
                },
                size: {
                    value: 6,
                    random: true,
                    anim: {
                        enable: false,
                        speed: 40,
                        size_min: 0.1,
                        sync: false,
                    },
                },
                line_linked: {
                    enable: true,
                    distance: 150,
                    color: "#ffffff",
                    opacity: 0.4,
                    width: 1,
                },
                move: {
                    enable: true,
                    speed: 6,
                    direction: "none",
                    random: false,
                    straight: false,
                    out_mode: "out",
                    bounce: false,
                    attract: {
                        enable: false,
                        rotateX: 600,
                        rotateY: 1200,
                    },
                },
            },
            interactivity: {
                detect_on: "canvas",
                events: {
                    onhover: {
                        enable: true,
                        mode: "grab",
                    },
                    onclick: {
                        enable: true,
                        mode: "push",
                    },
                    resize: true,
                },
                modes: {
                    grab: {
                        distance: 140,
                        line_linked: {
                            opacity: 1,
                        },
                    },
                    bubble: {
                        distance: 400,
                        size: 40,
                        duration: 2,
                        opacity: 8,
                        speed: 3,
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4,
                    },
                    push: {
                        particles_nb: 4,
                    },
                    remove: {
                        particles_nb: 2,
                    },
                },
            },
            retina_detect: true,
        });
    });

    function sendEmail(e) {
        e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm("service_buwxs38", "template_95e8a7r", e.target, "NfB0YCw-NDNwuaPml").then(
            (result) => {
                window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            },
            (error) => {
                console.log(error.text);
            }
        );
    }
    return (
        <div>
            <div className="breadcrumbs">
                <div className="page-header d-flex align-items-center">
                    <div ref={particle} id="particles-js"></div>
                    <div className="container position-relative">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6 text-center">
                                <h2 data-aos="fade-up">Comprehensive ERP Solution</h2>
                                <p data-aos="fade-up" data-aos-delay="400">
                                    Elevate Your Business with Powerful ERP Solution!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact" className="contact-section section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 erp-mail-image" data-aos="fade-right">
                            <img src="/assets/images/erp-mailing.jpg" alt="ERP images" />
                        </div>
                        <div className="col-lg-6 col-md-12" data-aos="fade-left">
                            <div className="contact-form-box contact-form contact-form-3">
                                <div className="form-container-box">
                                    <form className="contact-form form" onSubmit={sendEmail}>
                                        <div className="controls">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="text" className="form-control" id="name" name="name" placeholder="Name*" required="required" data-error="Name is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="email" className="form-control" id="email" name="email" placeholder="Business Email*" required="required" data-error="Valid email is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group form-input-box">
                                                        <input type="phone" className="form-control" id="phone" name="phone" placeholder="Phone*" required="required" data-error="Valid number is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="text" className="form-control" id="company" name="company" placeholder="Company*" required="required" data-error="Company is required." />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-input-box">
                                                        <input type="number" className="form-control" id="noEmployees" name="noEmployees" placeholder="No of Employees*" required="required" />
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group form-input-box">
                                                        <textarea
                                                            className="form-control"
                                                            id="message"
                                                            name="message"
                                                            rows="7"
                                                            placeholder="Write Your Message*"
                                                            required="required"
                                                            data-error="Please, leave us a message."
                                                        ></textarea>
                                                        <div className="help-block with-errors"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <button type="submit" value="Send" data-text="Send Message">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
