
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Home from "./components/Home";
import Services from "./pages/Services";
import Portfolios from "./pages/Portfolio";
import Testimonials from "./pages/Testimonials";
import Teams from "./pages/Teams";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import Why_Choose_Us from "./pages/Why_Choose_Us";
import App_Development from "./services/App_Development"
import Web_development from "./services/Web_Development";
import Software_development from "./services/Software_Development";
import It_consulting from "./services/It_Consulting";
import Digital_Marketing from "./services/Digital_Marketing";
import Cloud_services from "./services/Cloud_Services";
import Privacy_Policy from "./pages/Privacy_Policy";
import Terms_Conditions from "./pages/Terms_Conditions";
import About from "./pages/About";
import Healthcare_Lifesciences from "./solutions/Healthcare_Lifesciences";
import Banking_Finance_Insurance from "./solutions/Banking_Finance_Insurance";
import E_Commerce_Solutions from "./solutions/E_Commerce_Solutions";
import On_Demand_Delivery from "./solutions/On_Demand_Delivery";
import Real_Estate from "./solutions/Real_Estate";
import Transport_Logistics from "./solutions/Transport_Logistics";
import Education_ELearning from "./solutions/Education_ELearning";
import Enterprise_Retail_Manufacturing from "./solutions/Enterprise_Retail_Manufacturing";
import Travel_Hospitality from "./solutions/Travel_Hospitality";
import Media_Entertainment from "./solutions/Media_Entertainment";
import Social_Networking from "./solutions/Social_Networking";
import Crafting_Car_Service from "./blogs/Crafting_Car_Service";
import Custom_Websites_Development from "./blogs/Custom_Websites_Development";
import Ai_Revolutionizing_Agriculture from "./blogs/Ai_Revolutionizing_Agriculture";
import Page404 from "./pages/Page404";
import Product from "./pages/Product";
import Erp_Solution from "./pages/Erp_Solution";
import Book_Demo from "./pages/Book_Demo";


function App() {
  return (
      <Routes>
        {/* main menu route */}
        <Route path="" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolios />} />
          {/* <Route path="/testimonials" element={<Testimonials />} /> */}
          <Route path="/teams" element={<Teams />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          {/* <Route path="/products" element={<Product />} /> */}
          <Route path="/erp-solution" element={<Erp_Solution />} />
          <Route path="*" element={<Page404/>} />
          <Route path="/why-choose-us" element={<Why_Choose_Us />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/terms-conditions" element={<Terms_Conditions />} />
          <Route path="/book-demo" element={<Book_Demo />} />
          {/* Services Sub Routing */}
          <Route path="/services/app-development" element={<App_Development />} />
          <Route path="/services/web-development" element={<Web_development />} />
          <Route path="/services/software-development" element={<Software_development />} />
          <Route path="/services/it-consulting" element={<It_consulting />} />
          <Route path="/services/digital-marketing" element={<Digital_Marketing />} />
          <Route path="/services/cloud-services" element={<Cloud_services />} />
          {/* Solutions Sub Routing */}
          <Route path="/solutions/healthcare-lifesciences" element={<Healthcare_Lifesciences />} />
          <Route path="/solutions/banking-finance-insurance" element={<Banking_Finance_Insurance />} />
          <Route path="/solutions/e-commerce-solutions" element={<E_Commerce_Solutions />} />
          <Route path="/solutions/on-demand-delivery" element={<On_Demand_Delivery />} />
          <Route path="/solutions/real-estate" element={<Real_Estate />} />
          <Route path="/solutions/transport-logistics" element={<Transport_Logistics />} />
          <Route path="/solutions/education-eLearning" element={<Education_ELearning />} />
          <Route path="/solutions/enterprise-retail-manufacturing" element={<Enterprise_Retail_Manufacturing />} />
          <Route path="/solutions/travel-hospitality" element={<Travel_Hospitality />} />
          <Route path="/solutions/media-entertainment" element={<Media_Entertainment />} />
          <Route path="/solutions/social-networking" element={<Social_Networking />} />
          {/* Blogs Sub Routing */}
          <Route path="/blogs/crafting-a-car-service-center-application-guide" element={<Crafting_Car_Service />} />
          <Route path="/blogs/decide:-no-code-vs-custom-websites-development" element={<Custom_Websites_Development />} />
          <Route path="/blogs/ai-revolutionizing-agriculture:-farming's-future-unveiled" element={<Ai_Revolutionizing_Agriculture />} />
        </Route>
      </Routes>
  );
}

export default App;
